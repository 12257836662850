<template>
  <div id="ObraLista2" class="pa-6">

    <div class="wrapper" style="height:440px">
      <table cellspacing="0">
        <!-- Cabeçalho -->
        <thead>
          <!-- 1ª Linha Cabeçalho - Codigo / Descrição / Ult. Compra / Destinos -->
          <tr>
            <th rowspan="2" class="fixed-left text-center thead-first" style="--margem:0px; min-width:70px!important; max-width:70px!important; width:70px!important">Cod</th>
            <th rowspan="2" class="fixed-left text-center thead-first" style="--margem:70px; min-width:300px!important; max-width:300px!important; width:300px!important">Descrição do Item</th>
            <th rowspan="2" class="fixed-left text-center thead-first" style="--margem:370px; width:70px!important">Última Compra</th>
            <th v-for="(item, index) in obras" :key="index" colspan="4" class="text-center thead-first">
              {{ item }}
            </th>
            <th rowspan="2" class="fixed-right text-center thead-first" style="--margem:0px; width:100px!important">Total</th>
          </tr>
          <!-- 2ª Linha Cabeçalho - Qtde. / Valor unit. / Desconto / Valor Total -->
          <tr>
            <template v-for="(item, index_obra) in obras">
              <th :key="'Obra' + index_obra + '_Qtde_h'" class="text-center" style="width:100px!important;min-width:100px!important">Qtde.</th>
              <th :key="'Obra' + index_obra + '_ValorUnit_h'" class="text-center" style="width:100px!important;min-width:100px!important">Valor unit.</th>
              <th :key="'Obra' + index_obra + '_Desconto_h'" class="text-center" style="width:100px!important;min-width:100px!important">Desconto</th>
              <th :key="'Obra' + index_obra + '_ValorTotal_h'" class="text-center" style="width:100px!important;min-width:100px!important">Valor Total</th>
            </template>
          </tr>
        </thead>

        <!-- Corpo da Grade -->
        <tbody>
          <tr v-for="(item, index_dados) in dados" :key="index_dados">
            <td class="fixed-left text-right fundo-branco" style="--margem:0px">{{ item.insumo_cod }}</td>
            <td class="fixed-left text-justify fundo-branco" style="--margem:70px">{{ item.insumo_desc }}</td>
            <td class="fixed-left text-right fundo-branco" style="--margem:370px">{{ item.insumo_valor }}</td>

            <template v-for="(item_obra, index_obra) in obras">
              <!-- Qtde -->
              <td v-if="index_obra >= 0" :key="'Obra' + index_obra + '_Qtde'" v-bind:style="{'backgroundColor': item['Obra' + index_obra + '_cor']+'!important'}" class="text-right pa-0 fundo-branco">
              <VuetifyMoney
                :ref="'Obra' + index_obra + '_Qtde'"
                :id="'Obra' + index_obra + '_Qtde'"
                style="max-width: 100px"
                :value="item['Obra' + index_obra + '_Qtde']"
                v-model="item['Obra' + index_obra + '_Qtde']"
                dense
                outlined
                reverse
                hide-details
                single-line                        
                :clearable="false"
                :options = '{ "locale": "pt-BR",
                              "prefix": "",
                              "suffix": "",
                              "length": 11,
                              "precision": 2
                            }'
              >
              </VuetifyMoney>
            </td>
            <!-- Valor Unitário -->
            <td v-if="index_obra >= 0" :key="'Obra' + index_obra + '_ValorUnit'" v-bind:style="{'backgroundColor': item['Obra' + index_obra + '_cor']+'!important'}" class="text-right pa-0 fundo-branco">
              <VuetifyMoney
                :ref="'Obra' + index_obra + '_ValorUnit'"
                :id="'Obra' + index_obra + '_ValorUnit'"
                style="max-width: 100px"
                :value="item['Obra' + index_obra + '_ValorUnit']"
                v-model="item['Obra' + index_obra + '_ValorUnit']"
                dense
                outlined
                reverse
                hide-details
                single-line                        
                :clearable="false"
                :options = '{ "locale": "pt-BR",
                              "prefix": "",
                              "suffix": "",
                              "length": 11,
                              "precision": 2
                            }'
              >
              </VuetifyMoney>
            </td>
            <!-- Desconto Unitário -->
            <td v-if="index_obra >= 0" :key="'Obra' + index_obra + '_DescontoUnit'" v-bind:style="{'backgroundColor': item['Obra' + index_obra + '_cor']+'!important'}" class="text-right pa-0 fundo-branco">
              <VuetifyMoney
                :ref="'Obra' + index_obra + '_DescontoUnit'"
                :id="'Obra' + index_obra + '_DescontoUnit'"
                style="max-width: 100px"
                :value="item['Obra' + index_obra + '_DescontoUnit']"
                v-model="item['Obra' + index_obra + '_DescontoUnit']"
                dense
                outlined
                reverse
                hide-details
                single-line                        
                :clearable="false"
                :options = '{ "locale": "pt-BR",
                              "prefix": "",
                              "suffix": "",
                              "length": 11,
                              "precision": 2
                            }'
              >
              </VuetifyMoney>
            </td>
            <!-- Valor Unitário -->
            <td v-if="index_obra >= 0" :key="'Obra' + index_obra + '_ValorTotal'" v-bind:style="{'backgroundColor': item['Obra' + index_obra + '_cor']+'!important'}" class="text-right pa-0" >
              <VuetifyMoney
                :ref="'Obra' + index_obra + '_ValorTotal'"
                :id="'Obra' + index_obra + '_ValorTotal'"
                :value="item['Obra' + index_obra + '_ValorTotal']"
                v-model="item['Obra' + index_obra + '_ValorTotal']"
                dense
                outlined
                reverse
                hide-details
                single-line                        
                :clearable="false"
                :options = '{ "locale": "pt-BR",
                              "prefix": "",
                              "suffix": "",
                              "length": 11,
                              "precision": 2
                            }'
              >
              </VuetifyMoney>
            </td> 
          </template>
            <td class="fixed-right text-right fundo-branco">{{ item.Total }}</td>
          </tr>

        </tbody>
        
        <!-- Rodapé -->
        <tfoot>
          <tr>
            <td colspan="3" class="fixed-left text-left" style="--margem:0px;">{{ dados.length }} Itens</td>
            <td class="text-right">234</td>
            <td class="text-right">.</td>
            <td class="text-right">124.99</td>
            <td class="text-right">3249.78</td>
            <td class="text-right">231</td>
            <td class="text-right">.</td>
            <td class="text-right">12.90</td>
            <td class="text-right">4560.98</td>
            <td class="fixed-right text-right">4230.89</td>
          </tr>
        </tfoot>

      </table>
    </div>

    <!-- <VuetifyMoney></VuetifyMoney> -->
  </div>
</template>

<script>
import VuetifyMoney from '../../../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue';
import moment from "moment";
import {  COR_PRINCIPAL,
          COR_SECUNDARIA,
          COR_BACKGROUND,
          COR_SUBTITULO,
          COR_SOMBRA,
          COR_BORDA,
          COR_PLACEHOLDER,
          COR_BACKGROUND_TEXT } from "../../../../services/constantes"

const COR_RODAPE = '#35518F';

export default {
  name: 'ObraLista',
  components : {
                VuetifyMoney
                },
  data() {
    return {
      COR_PRINCIPAL       : COR_PRINCIPAL,
      COR_SECUNDARIA      : COR_SECUNDARIA,
      COR_BACKGROUND      : COR_BACKGROUND,
      COR_SUBTITULO       : COR_SUBTITULO,
      COR_SOMBRA          : COR_SOMBRA,
      COR_BORDA           : COR_BORDA,
      COR_PLACEHOLDER     : COR_PLACEHOLDER,
      COR_BACKGROUND_TEXT : COR_BACKGROUND_TEXT,

      obras : [ 'Santa Helena de Franca','Araguaia Materiais'],

      // Exemplo de dados, ajuste conforme necessário
          dados : [
            {
                "insumo_cod": 1,
                "insumo_desc": "Bloco Cerâmico Vedação 9x19x19cm Cerâmica Nova Conquista",
                "insumo_valor": 23.30,
                "Obra0_Qtde": 15,
                "Obra0_cor": "#61E786",
                "Obra0_ValorUnit": 25.00,
                "Obra0_DescontoUnit": 3.00,
                "Obra0_ValorTotal": 330.00,
                "Obra1_Qtde": null,
                "Obra1_ValorUnit": null,
                "Obra1_DescontoUnit": null,
                "Obra1_ValorTotal": null,
                "Total": 330.00
            },
            {
                "insumo_cod": 2,
                "insumo_desc": "Cimento CP II 50kg Votoran",
                "insumo_valor": 25.50,
                "Obra0_Qtde": null,
                "Obra0_ValorUnit": null,
                "Obra0_DescontoUnit": null,
                "Obra0_ValorTotal": null,
                "Obra1_Qtde": 20,
                "Obra1_cor": "#61E786",
                "Obra1_ValorUnit": 26.50,
                "Obra1_DescontoUnit": 1.50,
                "Obra1_ValorTotal": 500.00,
                "Total": 500.00
            },
            {
                "insumo_cod": 3,
                "insumo_desc": "Areia Média 1m³",
                "insumo_valor": 85.00,
                "Obra0_Qtde": 5,
                "Obra0_ValorUnit": 86.00,
                "Obra0_DescontoUnit": 2.00,
                "Obra0_ValorTotal": 420.00,
                "Obra1_Qtde": 75.00,
                "Obra1_cor": "#61E786",
                "Obra1_ValorUnit": 2.00,
                "Obra1_DescontoUnit": 0.00,
                "Obra1_ValorTotal": 150.00,
                "Total": 570.00
            },
            {
                "insumo_cod": 4,
                "insumo_desc": "Tijolo Maciço 9x19x29cm",
                "insumo_valor": 1.10,
                "Obra0_Qtde": null,
                "Obra0_ValorUnit": null,
                "Obra0_DescontoUnit": null,
                "Obra0_ValorTotal": null,
                "Obra1_Qtde": 1000,
                "Obra1_cor": "#61E786",
                "Obra1_ValorUnit": 1.15,
                "Obra1_DescontoUnit": 0.05,
                "Obra1_ValorTotal": 1100.00,
                "Total": 1100.00
            },
            {
                "insumo_cod": 5,
                "insumo_desc": "Cal Hidratada 20kg",
                "insumo_valor": 8.90,
                "Obra0_Qtde": 10,
                "Obra0_cor": "#61E786",
                "Obra0_ValorUnit": 9.00,
                "Obra0_DescontoUnit": 0.10,
                "Obra0_ValorTotal": 88.00,
                "Obra1_Qtde": null,
                "Obra1_ValorUnit": null,
                "Obra1_DescontoUnit": null,
                "Obra1_ValorTotal": null,
                "Total": 88.00
            },
            {
                "insumo_cod": 6,
                "insumo_desc": "Vergalhão CA-50 8mm 12m",
                "insumo_valor": 35.00,
                "Obra0_Qtde": null,
                "Obra0_ValorUnit": null,
                "Obra0_DescontoUnit": null,
                "Obra0_ValorTotal": null,
                "Obra1_Qtde": 50,
                "Obra1_cor": "#61E786",
                "Obra1_ValorUnit": 36.00,
                "Obra1_DescontoUnit": 1.00,
                "Obra1_ValorTotal": 1750.00,
                "Total": 1750.00
            },
            {
                "insumo_cod": 7,
                "insumo_desc": "Telha Cerâmica Colonial 41x21cm",
                "insumo_valor": 3.20,
                "Obra0_Qtde": 200,
                "Obra0_cor": "#61E786",
                "Obra0_ValorUnit": 3.25,
                "Obra0_DescontoUnit": 0.05,
                "Obra0_ValorTotal": 640.00,
                "Obra1_Qtde": null,
                "Obra1_ValorUnit": null,
                "Obra1_DescontoUnit": null,
                "Obra1_ValorTotal": null,
                "Total": 640.00
            },
            {
                "insumo_cod": 8,
                "insumo_desc": "Porta de Madeira Angelim 210x80cm",
                "insumo_valor": 450.00,
                "Obra0_Qtde": 10,
                "Obra0_cor": "#61E786",
                "Obra0_ValorUnit": 200,
                "Obra0_DescontoUnit": 20,
                "Obra0_ValorTotal": 2000.00,
                "Obra1_Qtde": 5,
                "Obra1_ValorUnit": 455.00,
                "Obra1_DescontoUnit": 5.00,
                "Obra1_ValorTotal": 2225.00,
                "Total": 2225.00
            },
            {
                "insumo_cod": 9,
                "insumo_desc": "Tubo PVC 50mm 6m Tigre",
                "insumo_valor": 20.00,
                "Obra0_Qtde": 25,
                "Obra0_cor": "#61E786",
                "Obra0_ValorUnit": 20.50,
                "Obra0_DescontoUnit": 0.50,
                "Obra0_ValorTotal": 487.50,
                "Obra1_Qtde": null,
                "Obra1_ValorUnit": null,
                "Obra1_DescontoUnit": null,
                "Obra1_ValorTotal": null,
                "Total": 487.50
            },
            {
                "insumo_cod": 10,
                "insumo_desc": "Argamassa ACII 20kg Quartzolit",
                "insumo_valor": 22.00,
                "Obra0_Qtde": null,
                "Obra0_ValorUnit": null,
                "Obra0_DescontoUnit": null,
                "Obra0_ValorTotal": null,
                "Obra1_Qtde": 40,
                "Obra1_cor": "#61E786",
                "Obra1_ValorUnit": 22.50,
                "Obra1_DescontoUnit": 0.50,
                "Obra1_ValorTotal": 880.00,
                "Total": 880.00
            }
        ]
            };
  },
  mounted() {
    console.log(moment(), this.COR_PRINCIPAL);
    document.documentElement.style.setProperty('--COR_PRINCIPAL', this.COR_PRINCIPAL);
    document.documentElement.style.setProperty('--COR_SECUNDARIA', this.COR_SECUNDARIA);
    document.documentElement.style.setProperty('--COR_BACKGROUND', this.COR_BACKGROUND);
    document.documentElement.style.setProperty('--COR_SUBTITULO', this.COR_SUBTITULO);
    document.documentElement.style.setProperty('--COR_SOMBRA', this.COR_SOMBRA);
    document.documentElement.style.setProperty('--COR_PLACEHOLDER', this.COR_PLACEHOLDER);
    document.documentElement.style.setProperty('--COR_BACKGROUND_TEXT', this.COR_BACKGROUND_TEXT);
    document.documentElement.style.setProperty('--COR_BORDA', this.COR_BORDA);

  }
}
</script>

<style scoped>

  /* ------------------------------------------------------------------------------------------------------- */
  /* ------------------------------------------------------------------------------------------------------- */
  /* grade geral ------------------------------ */
  /* ------------------------------------------------------------------------------------------------------- */
  /* ------------------------------------------------------------------------------------------------------- */

  .wrapper {
    margin: auto;
    position: -webkit-sticky;    
    overflow-x:scroll;
  }

  table {
    /* table-layout: fixed;  */
    /* width: 100%; */
    background: white;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 4;
  }

  thead th {
    /* background-color: lightgray !important; */
    background-color: var(--COR_PRINCIPAL) !important;
    color : lightgray
  }

  .thead-first {
    border-top:1px solid lightgray!important;
  }

  tbody, th, td {
    vertical-align: top;
    text-align: left;
    padding: 3px;
    border-right:1px solid lightgray;
    border-bottom:1px solid lightgray;
    background-color: white !important;
    height: 25px !important;
  }

  /* Colunas Fixas a esquerda  --------------------- */
  th.fixed-left, td.fixed-left {
    position:sticky;
    background: white;
    /* border-left:1px solid gray; */    
    left: var(--margem, 0px);  
    z-index: 2 !important;  
  }

  th.fixed-left:first-child, td.fixed-left:first-child {
    border-left:1px solid lightgray!important;
  }

  /* Colunas Fixas a direita  --------------------- */
  th.fixed-right, td.fixed-right {
    position:sticky;
    background: white;
    right: var(--margem, 0px);  
    z-index: 2 !important;  
  }

  th.fixed-right:last-child {
    border-right:1px solid lightgray!important;
    border-left:1px solid lightgray!important;
  }
  
  td.fixed-right:last-child {
    border-right:1px solid lightgray!important;
    border-left:1px solid lightgray!important;
  }

  tfoot {
    border-right: none !important;
    position: sticky;
    bottom: 0px;
    z-index: 2 !important;
  }
  
  tfoot td {
    border-top:1px solid lightgray!important;
    background-color: var(--COR_PRINCIPAL) !important;
    color : lightgray
  }

  /* Cor das bordas dos campos dos inputs */
  ::v-deep .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
      color: var(--COR_SOMBRA)!important;
      opacity: 0!important;
    }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .text-center {
    text-align: center;
  }

  .text-justify {
    text-align: justify;
  }

  .fundo-branco {
    background: white;    
  }

  .fundo-fixos {
    background: var(--COR_PRINCIPAL) !important;
  }

</style>
